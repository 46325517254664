///*----------------------------------*\
//  #COLORS
//\*----------------------------------*/

// Set up a colour palette which allows us to theme the entire project from one
// location.

// Colour palette.
$color-brand-primary:               #5229ba !default;
$color-brand-primary-75:            lighten($color-brand-primary, 25%) !default;
$color-brand-primary-40:            lighten($color-brand-primary, 60%) !default;
$color-brand-primary-10:            lighten($color-brand-primary, 80%) !default;
$color-brand-primary-02:            lighten($color-brand-primary, 98%) !default;

$color-brand-primary-a80: 			transparentize($color-brand-primary, 0.1) !default;

$color-brand-primary-highlight:     lighten($color-brand-primary, 5%) !default;
$color-brand-primary-shadow:        darken($color-brand-primary,  5%) !default;

$color-brand-secondary:             #03b9f8 !default;
$color-brand-secondary-75:            lighten($color-brand-secondary, 25%) !default;
$color-brand-secondary-40:            lighten($color-brand-secondary, 60%) !default;
$color-brand-secondary-10:            lighten($color-brand-secondary, 80%) !default;
$color-brand-secondary-02:            lighten($color-brand-secondary, 98%) !default;


$color-brand-secondary-highlight:   lighten($color-brand-secondary, 5%) !default;
$color-brand-secondary-shadow:      darken($color-brand-secondary,  5%) !default;

$color-ui:                          #e4e4e4 !default;

$color-grey:                        #bbbbbb !default;
$color-grey-dark:                   #535353 !default;
$color-grey-xdark:                  #323232 !default;
$color-grey-light:                  #dddddd !default;
$color-grey-xlight:                 #f5f5f5 !default;

$color-white:                       #fff !default;

$color-black:                       #000 !default;

$color-positive:                    #F1AF49;
$color-neutral:                     #0099fa;
$color-warning:                     #fd9434;
$color-error:                       #ff4444;

// Theme colors.
$theme-colors: (
    bereidingProductie:  ( primary: #79cccd, primary75: #99d9da, primary40: #c8eaea, primary10: #f0f9f9 ),
    veehouderij:         ( primary: #b1b498, primary75: #c4c6b0, primary40: #dfe0d5, primary10: #f6f7f4 ),
    teeltVanGewassen:    ( primary: #c6a893, primary75: #d4bdad, primary40: #e8dbd2, primary10: #f8f5f3 ),
    import:              ( primary: #78b6e4, primary75: #98c8ec, primary40: #c7e1f5, primary10: #f0f7fc ),
    opslag:              ( primary: #b0b6c8, primary75: #c3c8d5, primary40: #dfe1e8, primary10: #f6f7f9 ),
    handel:              ( primary: #edc289, primary75: #f2d1a3, primary40: #f7e6ce, primary10: #fdf8f2 ),
    aquacultuur:         ( primary: #97d1a3, primary75: #b0dcb9, primary40: #d5ecd9, primary10: #f4faf5 ),
);

// werkprocessen orange
$color-aansluitingen100: #F1AF49;
$color-aansluitingen80: #F4BF6B;
$color-aansluitingen60: #F4D39E;
$color-aansluitingen40: #F4E7D1;
$color-aansluitingen20: #F1EADF;


// werkprocessen blue
$color-hoofdleidingen100: #4DC7F2;
$color-hoofdleidingen80: #89DAF7;
$color-hoofdleidingen60: #BDEAFA;
$color-hoofdleidingen40: #EAF8FD;
$color-hoofdleidingen20: #EAF8FD;


// Reassign literal variables to meaningful ones.
$color-page-text:                       $color-brand-secondary;
$color-page-background:                 #ffffff;

$color-logo:                            $color-black;
$color-logo-primary:                    $color-brand-primary;
$color-logo-secondary:                  $color-brand-primary;

$color-links:                           $color-brand-primary; //inherit; ? it was 'inherit' but doesnt state why...
$color-links-hover:                     $color-brand-primary-shadow;
$color-links-prose:                     $color-brand-primary;
$color-links-prose-hover:               $color-brand-primary-shadow;

$color-page-head:                       #fff;
$color-page-head-background:            $color-brand-secondary;

$color-masthead-background:             $color-brand-secondary;

$color-btn-text:                        $color-white;
$color-btn-text-hover:                  $color-grey-xlight;
$color-btn-background:                  $color-brand-primary;
$color-btn-background-hover:            $color-brand-primary-shadow;
// $color-btn-secondary:                   #fff;
// $color-btn-secondary-background:        $color-brand-secondary;
// $color-btn-secondary-background-hover:  $color-brand-secondary-shadow;

// $color-calendar:                        #fff;
// $color-calendar-title:                  #fff;
// $color-calendar-title-background:       $color-brand-primary;
// $color-calendar-busy:                   #fff;
// $color-calendar-busy-background:        $color-brand-secondary;
// $color-calendar-outside:                $color-grey-light;

$color-page-foot:                       #fff;
$color-page-foot-background:            $color-grey-dark;;

$color-panel-background:                #fff;
$color-panel-inverse:                   #fff;
$color-panel-inverse-background:        $color-brand-primary;
$color-panel-link:                      $color-grey-dark;
$color-panel-link-hover:                $color-brand-primary;

$color-testimonial-background:          #fff;
$color-testimonial-border:              $color-brand-primary;
$color-testimonial-source:              $color-grey;

$color-headline:                        $color-grey-light;

$color-sub-content-bg:                  rgba(0, 0, 0, 0.05);
$color-sub-content:                     $color-grey;

$color-pagination:                      $color-white;
$color-pagination-border:               $color-pagination;
$color-pagination-bg:                   $color-brand-primary;
$color-pagination-bg-hover:             $color-brand-primary-shadow;
$color-pagination-bg-current:           $color-brand-primary-highlight;
