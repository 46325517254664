/*------------------------------------*\
  #COMPAT -- icons
\*------------------------------------*/

.c-icon--circle {
    color: $color-brand-primary;
}

@each $themeName, $themeColors in $theme-colors {
    .t-#{$themeName} {
        .c-icon--circle {
            color: #{map-get($themeColors, "primary")}; // {1}
        }
    }
}
