/*------------------------------------*\
  #COMPAT -- Headings
\*------------------------------------*/

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    color: $color-brand-primary;
}

.heading--primary {
    color: $color-brand-primary;
}

@each $themeName, $themeColors in $theme-colors {
    .t-#{$themeName} {
        .heading--primary {
            color: #{map-get($themeColors, "primary")};
        }
    }
}
