/*----------------------------------*\
  #MQ
\*----------------------------------*/
/*------------------------------------*\
  #Z-INDEX
\*------------------------------------*/
/*------------------------------------*\
  #COMPAT -- Headings
\*------------------------------------*/
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #5229ba;
}

.heading--primary {
  color: #5229ba;
}

.t-bereidingProductie .heading--primary {
  color: #79cccd;
}

.t-veehouderij .heading--primary {
  color: #b1b498;
}

.t-teeltVanGewassen .heading--primary {
  color: #c6a893;
}

.t-import .heading--primary {
  color: #78b6e4;
}

.t-opslag .heading--primary {
  color: #b0b6c8;
}

.t-handel .heading--primary {
  color: #edc289;
}

.t-aquacultuur .heading--primary {
  color: #97d1a3;
}

/*------------------------------------*\
  #COMPAT -- input elems
\*------------------------------------*/
input,
textarea,
select {
  border: 1px solid #bbbbbb;
}
.c-form--error input,
.c-form--error textarea,
.c-form--error select {
  border-color: #fd9434;
}
.c-form--ok input,
.c-form--ok textarea,
.c-form--ok select {
  border-color: #F1AF49;
}

/*------------------------------------*\
  #COMPAT -- Links
\*------------------------------------*/
a {
  color: #5229ba;
}
a:hover, a:focus, a:active {
  color: #4924a5;
}

.t-bereidingProductie a {
  color: #79cccd;
}
.t-bereidingProductie a:hover, .t-bereidingProductie a:focus, .t-bereidingProductie a:active {
  color: #5bc1c2;
}

.t-veehouderij a {
  color: #b1b498;
}
.t-veehouderij a:hover, .t-veehouderij a:focus, .t-veehouderij a:active {
  color: #9fa380;
}

.t-teeltVanGewassen a {
  color: #c6a893;
}
.t-teeltVanGewassen a:hover, .t-teeltVanGewassen a:focus, .t-teeltVanGewassen a:active {
  color: #b89278;
}

.t-import a {
  color: #78b6e4;
}
.t-import a:hover, .t-import a:focus, .t-import a:active {
  color: #56a4dd;
}

.t-opslag a {
  color: #b0b6c8;
}
.t-opslag a:hover, .t-opslag a:focus, .t-opslag a:active {
  color: #98a0b7;
}

.t-handel a {
  color: #edc289;
}
.t-handel a:hover, .t-handel a:focus, .t-handel a:active {
  color: #e8b066;
}

.t-aquacultuur a {
  color: #97d1a3;
}
.t-aquacultuur a:hover, .t-aquacultuur a:focus, .t-aquacultuur a:active {
  color: #7bc48a;
}

/*------------------------------------*\
  #COMPAT -- Bacground-colors
\*------------------------------------*/
.o-background--primary {
  background-color: #5229ba;
}
.o-background--primary a {
  color: #fff;
}
.o-background--primary a:hover, .o-background--primary a:focus, .o-background--primary a:active {
  color: #dddddd;
}

.o-background--primary75 {
  background-color: #9c80e3;
}

.o-background--primary40 {
  background-color: white;
}

.o-background--primary10 {
  background-color: white;
}

.o-background--primary02 {
  background-color: white;
}

.o-background--secondary {
  background-color: #03b9f8;
}

.t-bereidingProductie .o-background--primary {
  color: #79cccd;
}
.t-bereidingProductie .o-background--primary75 {
  color: #99d9da;
}
.t-bereidingProductie .o-background--primary40 {
  color: #c8eaea;
}
.t-bereidingProductie .o-background--primary10 {
  color: #f0f9f9;
}
.t-veehouderij .o-background--primary {
  color: #b1b498;
}
.t-veehouderij .o-background--primary75 {
  color: #c4c6b0;
}
.t-veehouderij .o-background--primary40 {
  color: #dfe0d5;
}
.t-veehouderij .o-background--primary10 {
  color: #f6f7f4;
}
.t-teeltVanGewassen .o-background--primary {
  color: #c6a893;
}
.t-teeltVanGewassen .o-background--primary75 {
  color: #d4bdad;
}
.t-teeltVanGewassen .o-background--primary40 {
  color: #e8dbd2;
}
.t-teeltVanGewassen .o-background--primary10 {
  color: #f8f5f3;
}
.t-import .o-background--primary {
  color: #78b6e4;
}
.t-import .o-background--primary75 {
  color: #98c8ec;
}
.t-import .o-background--primary40 {
  color: #c7e1f5;
}
.t-import .o-background--primary10 {
  color: #f0f7fc;
}
.t-opslag .o-background--primary {
  color: #b0b6c8;
}
.t-opslag .o-background--primary75 {
  color: #c3c8d5;
}
.t-opslag .o-background--primary40 {
  color: #dfe1e8;
}
.t-opslag .o-background--primary10 {
  color: #f6f7f9;
}
.t-handel .o-background--primary {
  color: #edc289;
}
.t-handel .o-background--primary75 {
  color: #f2d1a3;
}
.t-handel .o-background--primary40 {
  color: #f7e6ce;
}
.t-handel .o-background--primary10 {
  color: #fdf8f2;
}
.t-aquacultuur .o-background--primary {
  color: #97d1a3;
}
.t-aquacultuur .o-background--primary75 {
  color: #b0dcb9;
}
.t-aquacultuur .o-background--primary40 {
  color: #d5ecd9;
}
.t-aquacultuur .o-background--primary10 {
  color: #f4faf5;
}
.o-background--positive {
  background-color: #F1AF49;
}

.o-background--neutral {
  background-color: #0099fa;
}

.o-background--warning {
  background-color: #fd9434;
}

.o-background--error {
  background-color: #ff4444;
}

/*------------------------------------*\
  #COMPAT -- Size
\*------------------------------------*/
@media (min-width: 20em) {
  .o-size__xtiny-1 {
    flex-basis: 6.3333333333%;
  }
  .o-size__xtiny-1--margin {
    flex-basis: calc(8.3333333333% - 1.4em - 2%);
  }
  .o-size__xtiny-2 {
    flex-basis: 14.6666666667%;
  }
  .o-size__xtiny-2--margin {
    flex-basis: calc(16.6666666667% - 1.4em - 2%);
  }
  .o-size__xtiny-3 {
    flex-basis: 23%;
  }
  .o-size__xtiny-3--margin {
    flex-basis: calc(25% - 1.4em - 2%);
  }
  .o-size__xtiny-4 {
    flex-basis: 31.3333333333%;
  }
  .o-size__xtiny-4--margin {
    flex-basis: calc(33.3333333333% - 1.4em - 2%);
  }
  .o-size__xtiny-5 {
    flex-basis: 39.6666666667%;
  }
  .o-size__xtiny-5--margin {
    flex-basis: calc(41.6666666667% - 1.4em - 2%);
  }
  .o-size__xtiny-6 {
    flex-basis: 48%;
  }
  .o-size__xtiny-6--margin {
    flex-basis: calc(50% - 1.4em - 2%);
  }
  .o-size__xtiny-7 {
    flex-basis: 56.3333333333%;
  }
  .o-size__xtiny-7--margin {
    flex-basis: calc(58.3333333333% - 1.4em - 2%);
  }
  .o-size__xtiny-8 {
    flex-basis: 64.6666666667%;
  }
  .o-size__xtiny-8--margin {
    flex-basis: calc(66.6666666667% - 1.4em - 2%);
  }
  .o-size__xtiny-9 {
    flex-basis: 73%;
  }
  .o-size__xtiny-9--margin {
    flex-basis: calc(75% - 1.4em - 2%);
  }
  .o-size__xtiny-10 {
    flex-basis: 81.3333333333%;
  }
  .o-size__xtiny-10--margin {
    flex-basis: calc(83.3333333333% - 1.4em - 2%);
  }
  .o-size__xtiny-11 {
    flex-basis: 89.6666666667%;
  }
  .o-size__xtiny-11--margin {
    flex-basis: calc(91.6666666667% - 1.4em - 2%);
  }
  .o-size__xtiny-12 {
    flex-basis: 98%;
  }
  .o-size__xtiny-12--margin {
    flex-basis: calc(100% - 1.4em - 2%);
  }
  .o-size__xtiny-auto {
    flex-basis: auto;
  }
  .o-size__grow-from-xtiny {
    flex-grow: 1;
  }
}
@media (min-width: 30em) {
  .o-size__tiny-1 {
    flex-basis: 6.3333333333%;
  }
  .o-size__tiny-1--margin {
    flex-basis: calc(8.3333333333% - 1.4em - 2%);
  }
  .o-size__tiny-2 {
    flex-basis: 14.6666666667%;
  }
  .o-size__tiny-2--margin {
    flex-basis: calc(16.6666666667% - 1.4em - 2%);
  }
  .o-size__tiny-3 {
    flex-basis: 23%;
  }
  .o-size__tiny-3--margin {
    flex-basis: calc(25% - 1.4em - 2%);
  }
  .o-size__tiny-4 {
    flex-basis: 31.3333333333%;
  }
  .o-size__tiny-4--margin {
    flex-basis: calc(33.3333333333% - 1.4em - 2%);
  }
  .o-size__tiny-5 {
    flex-basis: 39.6666666667%;
  }
  .o-size__tiny-5--margin {
    flex-basis: calc(41.6666666667% - 1.4em - 2%);
  }
  .o-size__tiny-6 {
    flex-basis: 48%;
  }
  .o-size__tiny-6--margin {
    flex-basis: calc(50% - 1.4em - 2%);
  }
  .o-size__tiny-7 {
    flex-basis: 56.3333333333%;
  }
  .o-size__tiny-7--margin {
    flex-basis: calc(58.3333333333% - 1.4em - 2%);
  }
  .o-size__tiny-8 {
    flex-basis: 64.6666666667%;
  }
  .o-size__tiny-8--margin {
    flex-basis: calc(66.6666666667% - 1.4em - 2%);
  }
  .o-size__tiny-9 {
    flex-basis: 73%;
  }
  .o-size__tiny-9--margin {
    flex-basis: calc(75% - 1.4em - 2%);
  }
  .o-size__tiny-10 {
    flex-basis: 81.3333333333%;
  }
  .o-size__tiny-10--margin {
    flex-basis: calc(83.3333333333% - 1.4em - 2%);
  }
  .o-size__tiny-11 {
    flex-basis: 89.6666666667%;
  }
  .o-size__tiny-11--margin {
    flex-basis: calc(91.6666666667% - 1.4em - 2%);
  }
  .o-size__tiny-12 {
    flex-basis: 98%;
  }
  .o-size__tiny-12--margin {
    flex-basis: calc(100% - 1.4em - 2%);
  }
  .o-size__tiny-auto {
    flex-basis: auto;
  }
  .o-size__grow-from-tiny {
    flex-grow: 1;
  }
}
@media (min-width: 37.5em) {
  .o-size__xsmall-1 {
    flex-basis: 6.3333333333%;
  }
  .o-size__xsmall-1--margin {
    flex-basis: calc(8.3333333333% - 1.4em - 2%);
  }
  .o-size__xsmall-2 {
    flex-basis: 14.6666666667%;
  }
  .o-size__xsmall-2--margin {
    flex-basis: calc(16.6666666667% - 1.4em - 2%);
  }
  .o-size__xsmall-3 {
    flex-basis: 23%;
  }
  .o-size__xsmall-3--margin {
    flex-basis: calc(25% - 1.4em - 2%);
  }
  .o-size__xsmall-4 {
    flex-basis: 31.3333333333%;
  }
  .o-size__xsmall-4--margin {
    flex-basis: calc(33.3333333333% - 1.4em - 2%);
  }
  .o-size__xsmall-5 {
    flex-basis: 39.6666666667%;
  }
  .o-size__xsmall-5--margin {
    flex-basis: calc(41.6666666667% - 1.4em - 2%);
  }
  .o-size__xsmall-6 {
    flex-basis: 48%;
  }
  .o-size__xsmall-6--margin {
    flex-basis: calc(50% - 1.4em - 2%);
  }
  .o-size__xsmall-7 {
    flex-basis: 56.3333333333%;
  }
  .o-size__xsmall-7--margin {
    flex-basis: calc(58.3333333333% - 1.4em - 2%);
  }
  .o-size__xsmall-8 {
    flex-basis: 64.6666666667%;
  }
  .o-size__xsmall-8--margin {
    flex-basis: calc(66.6666666667% - 1.4em - 2%);
  }
  .o-size__xsmall-9 {
    flex-basis: 73%;
  }
  .o-size__xsmall-9--margin {
    flex-basis: calc(75% - 1.4em - 2%);
  }
  .o-size__xsmall-10 {
    flex-basis: 81.3333333333%;
  }
  .o-size__xsmall-10--margin {
    flex-basis: calc(83.3333333333% - 1.4em - 2%);
  }
  .o-size__xsmall-11 {
    flex-basis: 89.6666666667%;
  }
  .o-size__xsmall-11--margin {
    flex-basis: calc(91.6666666667% - 1.4em - 2%);
  }
  .o-size__xsmall-12 {
    flex-basis: 98%;
  }
  .o-size__xsmall-12--margin {
    flex-basis: calc(100% - 1.4em - 2%);
  }
  .o-size__xsmall-auto {
    flex-basis: auto;
  }
  .o-size__grow-from-xsmall {
    flex-grow: 1;
  }
}
@media (min-width: 45em) {
  .o-size__small-1 {
    flex-basis: 6.3333333333%;
  }
  .o-size__small-1--margin {
    flex-basis: calc(8.3333333333% - 1.4em - 2%);
  }
  .o-size__small-2 {
    flex-basis: 14.6666666667%;
  }
  .o-size__small-2--margin {
    flex-basis: calc(16.6666666667% - 1.4em - 2%);
  }
  .o-size__small-3 {
    flex-basis: 23%;
  }
  .o-size__small-3--margin {
    flex-basis: calc(25% - 1.4em - 2%);
  }
  .o-size__small-4 {
    flex-basis: 31.3333333333%;
  }
  .o-size__small-4--margin {
    flex-basis: calc(33.3333333333% - 1.4em - 2%);
  }
  .o-size__small-5 {
    flex-basis: 39.6666666667%;
  }
  .o-size__small-5--margin {
    flex-basis: calc(41.6666666667% - 1.4em - 2%);
  }
  .o-size__small-6 {
    flex-basis: 48%;
  }
  .o-size__small-6--margin {
    flex-basis: calc(50% - 1.4em - 2%);
  }
  .o-size__small-7 {
    flex-basis: 56.3333333333%;
  }
  .o-size__small-7--margin {
    flex-basis: calc(58.3333333333% - 1.4em - 2%);
  }
  .o-size__small-8 {
    flex-basis: 64.6666666667%;
  }
  .o-size__small-8--margin {
    flex-basis: calc(66.6666666667% - 1.4em - 2%);
  }
  .o-size__small-9 {
    flex-basis: 73%;
  }
  .o-size__small-9--margin {
    flex-basis: calc(75% - 1.4em - 2%);
  }
  .o-size__small-10 {
    flex-basis: 81.3333333333%;
  }
  .o-size__small-10--margin {
    flex-basis: calc(83.3333333333% - 1.4em - 2%);
  }
  .o-size__small-11 {
    flex-basis: 89.6666666667%;
  }
  .o-size__small-11--margin {
    flex-basis: calc(91.6666666667% - 1.4em - 2%);
  }
  .o-size__small-12 {
    flex-basis: 98%;
  }
  .o-size__small-12--margin {
    flex-basis: calc(100% - 1.4em - 2%);
  }
  .o-size__small-auto {
    flex-basis: auto;
  }
  .o-size__grow-from-small {
    flex-grow: 1;
  }
}
@media (min-width: 64em) {
  .o-size__medium-1 {
    flex-basis: 6.3333333333%;
  }
  .o-size__medium-1--margin {
    flex-basis: calc(8.3333333333% - 1.4em - 2%);
  }
  .o-size__medium-2 {
    flex-basis: 14.6666666667%;
  }
  .o-size__medium-2--margin {
    flex-basis: calc(16.6666666667% - 1.4em - 2%);
  }
  .o-size__medium-3 {
    flex-basis: 23%;
  }
  .o-size__medium-3--margin {
    flex-basis: calc(25% - 1.4em - 2%);
  }
  .o-size__medium-4 {
    flex-basis: 31.3333333333%;
  }
  .o-size__medium-4--margin {
    flex-basis: calc(33.3333333333% - 1.4em - 2%);
  }
  .o-size__medium-5 {
    flex-basis: 39.6666666667%;
  }
  .o-size__medium-5--margin {
    flex-basis: calc(41.6666666667% - 1.4em - 2%);
  }
  .o-size__medium-6 {
    flex-basis: 48%;
  }
  .o-size__medium-6--margin {
    flex-basis: calc(50% - 1.4em - 2%);
  }
  .o-size__medium-7 {
    flex-basis: 56.3333333333%;
  }
  .o-size__medium-7--margin {
    flex-basis: calc(58.3333333333% - 1.4em - 2%);
  }
  .o-size__medium-8 {
    flex-basis: 64.6666666667%;
  }
  .o-size__medium-8--margin {
    flex-basis: calc(66.6666666667% - 1.4em - 2%);
  }
  .o-size__medium-9 {
    flex-basis: 73%;
  }
  .o-size__medium-9--margin {
    flex-basis: calc(75% - 1.4em - 2%);
  }
  .o-size__medium-10 {
    flex-basis: 81.3333333333%;
  }
  .o-size__medium-10--margin {
    flex-basis: calc(83.3333333333% - 1.4em - 2%);
  }
  .o-size__medium-11 {
    flex-basis: 89.6666666667%;
  }
  .o-size__medium-11--margin {
    flex-basis: calc(91.6666666667% - 1.4em - 2%);
  }
  .o-size__medium-12 {
    flex-basis: 98%;
  }
  .o-size__medium-12--margin {
    flex-basis: calc(100% - 1.4em - 2%);
  }
  .o-size__medium-auto {
    flex-basis: auto;
  }
  .o-size__grow-from-medium {
    flex-grow: 1;
  }
}
@media (min-width: 80em) {
  .o-size__large-1 {
    flex-basis: 6.3333333333%;
  }
  .o-size__large-1--margin {
    flex-basis: calc(8.3333333333% - 1.4em - 2%);
  }
  .o-size__large-2 {
    flex-basis: 14.6666666667%;
  }
  .o-size__large-2--margin {
    flex-basis: calc(16.6666666667% - 1.4em - 2%);
  }
  .o-size__large-3 {
    flex-basis: 23%;
  }
  .o-size__large-3--margin {
    flex-basis: calc(25% - 1.4em - 2%);
  }
  .o-size__large-4 {
    flex-basis: 31.3333333333%;
  }
  .o-size__large-4--margin {
    flex-basis: calc(33.3333333333% - 1.4em - 2%);
  }
  .o-size__large-5 {
    flex-basis: 39.6666666667%;
  }
  .o-size__large-5--margin {
    flex-basis: calc(41.6666666667% - 1.4em - 2%);
  }
  .o-size__large-6 {
    flex-basis: 48%;
  }
  .o-size__large-6--margin {
    flex-basis: calc(50% - 1.4em - 2%);
  }
  .o-size__large-7 {
    flex-basis: 56.3333333333%;
  }
  .o-size__large-7--margin {
    flex-basis: calc(58.3333333333% - 1.4em - 2%);
  }
  .o-size__large-8 {
    flex-basis: 64.6666666667%;
  }
  .o-size__large-8--margin {
    flex-basis: calc(66.6666666667% - 1.4em - 2%);
  }
  .o-size__large-9 {
    flex-basis: 73%;
  }
  .o-size__large-9--margin {
    flex-basis: calc(75% - 1.4em - 2%);
  }
  .o-size__large-10 {
    flex-basis: 81.3333333333%;
  }
  .o-size__large-10--margin {
    flex-basis: calc(83.3333333333% - 1.4em - 2%);
  }
  .o-size__large-11 {
    flex-basis: 89.6666666667%;
  }
  .o-size__large-11--margin {
    flex-basis: calc(91.6666666667% - 1.4em - 2%);
  }
  .o-size__large-12 {
    flex-basis: 98%;
  }
  .o-size__large-12--margin {
    flex-basis: calc(100% - 1.4em - 2%);
  }
  .o-size__large-auto {
    flex-basis: auto;
  }
  .o-size__grow-from-large {
    flex-grow: 1;
  }
}
@media (min-width: 100em) {
  .o-size__xlarge-1 {
    flex-basis: 6.3333333333%;
  }
  .o-size__xlarge-1--margin {
    flex-basis: calc(8.3333333333% - 1.4em - 2%);
  }
  .o-size__xlarge-2 {
    flex-basis: 14.6666666667%;
  }
  .o-size__xlarge-2--margin {
    flex-basis: calc(16.6666666667% - 1.4em - 2%);
  }
  .o-size__xlarge-3 {
    flex-basis: 23%;
  }
  .o-size__xlarge-3--margin {
    flex-basis: calc(25% - 1.4em - 2%);
  }
  .o-size__xlarge-4 {
    flex-basis: 31.3333333333%;
  }
  .o-size__xlarge-4--margin {
    flex-basis: calc(33.3333333333% - 1.4em - 2%);
  }
  .o-size__xlarge-5 {
    flex-basis: 39.6666666667%;
  }
  .o-size__xlarge-5--margin {
    flex-basis: calc(41.6666666667% - 1.4em - 2%);
  }
  .o-size__xlarge-6 {
    flex-basis: 48%;
  }
  .o-size__xlarge-6--margin {
    flex-basis: calc(50% - 1.4em - 2%);
  }
  .o-size__xlarge-7 {
    flex-basis: 56.3333333333%;
  }
  .o-size__xlarge-7--margin {
    flex-basis: calc(58.3333333333% - 1.4em - 2%);
  }
  .o-size__xlarge-8 {
    flex-basis: 64.6666666667%;
  }
  .o-size__xlarge-8--margin {
    flex-basis: calc(66.6666666667% - 1.4em - 2%);
  }
  .o-size__xlarge-9 {
    flex-basis: 73%;
  }
  .o-size__xlarge-9--margin {
    flex-basis: calc(75% - 1.4em - 2%);
  }
  .o-size__xlarge-10 {
    flex-basis: 81.3333333333%;
  }
  .o-size__xlarge-10--margin {
    flex-basis: calc(83.3333333333% - 1.4em - 2%);
  }
  .o-size__xlarge-11 {
    flex-basis: 89.6666666667%;
  }
  .o-size__xlarge-11--margin {
    flex-basis: calc(91.6666666667% - 1.4em - 2%);
  }
  .o-size__xlarge-12 {
    flex-basis: 98%;
  }
  .o-size__xlarge-12--margin {
    flex-basis: calc(100% - 1.4em - 2%);
  }
  .o-size__xlarge-auto {
    flex-basis: auto;
  }
  .o-size__grow-from-xlarge {
    flex-grow: 1;
  }
}

/*------------------------------------*\
  #COMPAT -- Article
\*------------------------------------*/
.c-article strong {
  color: #5229ba;
}

.c-article__mainimage {
  max-width: 90vw;
}

.t-bereidingProductie .c-article strong {
  color: #79cccd;
}

.t-veehouderij .c-article strong {
  color: #b1b498;
}

.t-teeltVanGewassen .c-article strong {
  color: #c6a893;
}

.t-import .c-article strong {
  color: #78b6e4;
}

.t-opslag .c-article strong {
  color: #b0b6c8;
}

.t-handel .c-article strong {
  color: #edc289;
}

.t-aquacultuur .c-article strong {
  color: #97d1a3;
}

/*------------------------------------*\
  #COMPAT -- Breadcrumbs
\*------------------------------------*/
.c-breadcrumbs i {
  color: #5229ba;
}
.c-breadcrumbs a {
  color: #535353;
}
.c-breadcrumbs a:hover, .c-breadcrumbs a:focus, .c-breadcrumbs a:active {
  color: #000;
}

.t-bereidingProductie .c-breadcrumbs i {
  color: #79cccd;
}

.t-veehouderij .c-breadcrumbs i {
  color: #b1b498;
}

.t-teeltVanGewassen .c-breadcrumbs i {
  color: #c6a893;
}

.t-import .c-breadcrumbs i {
  color: #78b6e4;
}

.t-opslag .c-breadcrumbs i {
  color: #b0b6c8;
}

.t-handel .c-breadcrumbs i {
  color: #edc289;
}

.t-aquacultuur .c-breadcrumbs i {
  color: #97d1a3;
}

/*------------------------------------*\
  #COMPAT -- Button
\*------------------------------------*/
.c-btn {
  color: #fff;
  background-color: #5229ba;
}
.c-btn:hover, .c-btn:focus {
  color: #f5f5f5;
  background-color: #4924a5;
}
.c-btn:disabled {
  background-color: #dddddd;
}
.c-btn:disabled:hover, .c-btn:disabled:focus {
  color: #fff;
}

.c-btn--white {
  background-color: #fff;
  color: #000;
}
.c-btn--white:hover, .c-btn--white:focus {
  background-color: #f5f5f5;
  color: #0d0d0d;
}

.c-btn--black {
  background-color: #000;
}
.c-btn--black:hover, .c-btn--black:focus {
  background-color: #323232;
}

.c-btn__readmore {
  background-color: #fff;
  color: #5229ba;
  padding-top: 1.2em;
  padding-bottom: 1em;
}
.c-btn__readmore:hover, .c-btn__readmore:focus {
  background-color: #5229ba;
  color: #fff;
}
.c-btn__readmore > i {
  font-size: 0.85em;
  float: right;
}
.c-tile__article .c-btn__readmore {
  border-color: #5229ba;
}
.c-tile__article .c-btn__readmore:hover, .c-tile__article .c-btn__readmore:focus {
  border-color: #4924a5;
}

.t-bereidingProductie .c-btn {
  background-color: #79cccd;
}
.t-bereidingProductie .c-btn:hover, .t-bereidingProductie .c-btn:focus {
  background-color: #66c5c6;
}
.t-bereidingProductie .c-btn__readmore:hover, .t-bereidingProductie .c-btn__readmore:focus {
  background-color: #79cccd;
  color: #fff;
}

.t-veehouderij .c-btn {
  background-color: #b1b498;
}
.t-veehouderij .c-btn:hover, .t-veehouderij .c-btn:focus {
  background-color: #a6a989;
}
.t-veehouderij .c-btn__readmore:hover, .t-veehouderij .c-btn__readmore:focus {
  background-color: #b1b498;
  color: #fff;
}

.t-teeltVanGewassen .c-btn {
  background-color: #c6a893;
}
.t-teeltVanGewassen .c-btn:hover, .t-teeltVanGewassen .c-btn:focus {
  background-color: #bd9b82;
}
.t-teeltVanGewassen .c-btn__readmore:hover, .t-teeltVanGewassen .c-btn__readmore:focus {
  background-color: #c6a893;
  color: #fff;
}

.t-import .c-btn {
  background-color: #78b6e4;
}
.t-import .c-btn:hover, .t-import .c-btn:focus {
  background-color: #63abe0;
}
.t-import .c-btn__readmore:hover, .t-import .c-btn__readmore:focus {
  background-color: #78b6e4;
  color: #fff;
}

.t-opslag .c-btn {
  background-color: #b0b6c8;
}
.t-opslag .c-btn:hover, .t-opslag .c-btn:focus {
  background-color: #a1a8be;
}
.t-opslag .c-btn__readmore:hover, .t-opslag .c-btn__readmore:focus {
  background-color: #b0b6c8;
  color: #fff;
}

.t-handel .c-btn {
  background-color: #edc289;
}
.t-handel .c-btn:hover, .t-handel .c-btn:focus {
  background-color: #eab773;
}
.t-handel .c-btn__readmore:hover, .t-handel .c-btn__readmore:focus {
  background-color: #edc289;
  color: #fff;
}

.t-aquacultuur .c-btn {
  background-color: #97d1a3;
}
.t-aquacultuur .c-btn:hover, .t-aquacultuur .c-btn:focus {
  background-color: #85c993;
}
.t-aquacultuur .c-btn__readmore:hover, .t-aquacultuur .c-btn__readmore:focus {
  background-color: #97d1a3;
  color: #fff;
}

/*------------------------------------*\
  #COMPAT -- Flags
\*------------------------------------*/
.t-bereidingProductie .c-flag__body .name {
  color: #79cccd;
}

.t-veehouderij .c-flag__body .name {
  color: #b1b498;
}

.t-teeltVanGewassen .c-flag__body .name {
  color: #c6a893;
}

.t-import .c-flag__body .name {
  color: #78b6e4;
}

.t-opslag .c-flag__body .name {
  color: #b0b6c8;
}

.t-handel .c-flag__body .name {
  color: #edc289;
}

.t-aquacultuur .c-flag__body .name {
  color: #97d1a3;
}

/*------------------------------------*\
  #COMPAT -- forms
\*------------------------------------*/
.c-input__container label {
  color: #fff;
}
.c-input__container label:hover, .c-input__container label:focus {
  color: #f5f5f5;
}

.c-input__inline-label {
  background-color: #fff;
  color: #bbbbbb;
}
.c-input__inline-label:hover, .c-input__inline-label:focus {
  color: #000;
  background-color: #f5f5f5;
}

.c-input__checkboxlabel::before {
  border: 1px solid #bbbbbb;
}
.c-input__checkbox:checked + .c-input__checkboxlabel::before {
  border-color: #0099fa;
}

.c-input__radiolabel::before {
  border: 1px solid #bbbbbb;
}
.c-input__radio:checked + .c-input__radiolabel::before {
  border-color: #0099fa;
}

/*------------------------------------*\
  #COMPAT -- hero
\*------------------------------------*/
.t-bereidingProductie .c-hero__themecolor::after {
  background-color: #79cccd;
}
.t-bereidingProductie .c-hero__themecolor h1, .t-bereidingProductie .c-hero__themecolor h3 {
  color: #fff;
}
.t-bereidingProductie .c-hero__themecolor .c-hero__contentblock,
.t-bereidingProductie .c-hero__themecolor .c-hero__contentblock a {
  color: #79cccd;
}
.t-bereidingProductie .c-hero__themecolor a.c-breadcrumbs__page {
  color: #fff;
}
.t-bereidingProductie .c-hero__themecolor a.c-breadcrumbs__page:hover, .t-bereidingProductie .c-hero__themecolor a.c-breadcrumbs__page:focus {
  color: #c8eaea;
}

.t-veehouderij .c-hero__themecolor::after {
  background-color: #b1b498;
}
.t-veehouderij .c-hero__themecolor h1, .t-veehouderij .c-hero__themecolor h3 {
  color: #fff;
}
.t-veehouderij .c-hero__themecolor .c-hero__contentblock,
.t-veehouderij .c-hero__themecolor .c-hero__contentblock a {
  color: #b1b498;
}
.t-veehouderij .c-hero__themecolor a.c-breadcrumbs__page {
  color: #fff;
}
.t-veehouderij .c-hero__themecolor a.c-breadcrumbs__page:hover, .t-veehouderij .c-hero__themecolor a.c-breadcrumbs__page:focus {
  color: #dfe0d5;
}

.t-teeltVanGewassen .c-hero__themecolor::after {
  background-color: #c6a893;
}
.t-teeltVanGewassen .c-hero__themecolor h1, .t-teeltVanGewassen .c-hero__themecolor h3 {
  color: #fff;
}
.t-teeltVanGewassen .c-hero__themecolor .c-hero__contentblock,
.t-teeltVanGewassen .c-hero__themecolor .c-hero__contentblock a {
  color: #c6a893;
}
.t-teeltVanGewassen .c-hero__themecolor a.c-breadcrumbs__page {
  color: #fff;
}
.t-teeltVanGewassen .c-hero__themecolor a.c-breadcrumbs__page:hover, .t-teeltVanGewassen .c-hero__themecolor a.c-breadcrumbs__page:focus {
  color: #e8dbd2;
}

.t-import .c-hero__themecolor::after {
  background-color: #78b6e4;
}
.t-import .c-hero__themecolor h1, .t-import .c-hero__themecolor h3 {
  color: #fff;
}
.t-import .c-hero__themecolor .c-hero__contentblock,
.t-import .c-hero__themecolor .c-hero__contentblock a {
  color: #78b6e4;
}
.t-import .c-hero__themecolor a.c-breadcrumbs__page {
  color: #fff;
}
.t-import .c-hero__themecolor a.c-breadcrumbs__page:hover, .t-import .c-hero__themecolor a.c-breadcrumbs__page:focus {
  color: #c7e1f5;
}

.t-opslag .c-hero__themecolor::after {
  background-color: #b0b6c8;
}
.t-opslag .c-hero__themecolor h1, .t-opslag .c-hero__themecolor h3 {
  color: #fff;
}
.t-opslag .c-hero__themecolor .c-hero__contentblock,
.t-opslag .c-hero__themecolor .c-hero__contentblock a {
  color: #b0b6c8;
}
.t-opslag .c-hero__themecolor a.c-breadcrumbs__page {
  color: #fff;
}
.t-opslag .c-hero__themecolor a.c-breadcrumbs__page:hover, .t-opslag .c-hero__themecolor a.c-breadcrumbs__page:focus {
  color: #dfe1e8;
}

.t-handel .c-hero__themecolor::after {
  background-color: #edc289;
}
.t-handel .c-hero__themecolor h1, .t-handel .c-hero__themecolor h3 {
  color: #fff;
}
.t-handel .c-hero__themecolor .c-hero__contentblock,
.t-handel .c-hero__themecolor .c-hero__contentblock a {
  color: #edc289;
}
.t-handel .c-hero__themecolor a.c-breadcrumbs__page {
  color: #fff;
}
.t-handel .c-hero__themecolor a.c-breadcrumbs__page:hover, .t-handel .c-hero__themecolor a.c-breadcrumbs__page:focus {
  color: #f7e6ce;
}

.t-aquacultuur .c-hero__themecolor::after {
  background-color: #97d1a3;
}
.t-aquacultuur .c-hero__themecolor h1, .t-aquacultuur .c-hero__themecolor h3 {
  color: #fff;
}
.t-aquacultuur .c-hero__themecolor .c-hero__contentblock,
.t-aquacultuur .c-hero__themecolor .c-hero__contentblock a {
  color: #97d1a3;
}
.t-aquacultuur .c-hero__themecolor a.c-breadcrumbs__page {
  color: #fff;
}
.t-aquacultuur .c-hero__themecolor a.c-breadcrumbs__page:hover, .t-aquacultuur .c-hero__themecolor a.c-breadcrumbs__page:focus {
  color: #d5ecd9;
}

/*------------------------------------*\
  #COMPAT -- icons
\*------------------------------------*/
.c-icon--circle {
  color: #5229ba;
}

.t-bereidingProductie .c-icon--circle {
  color: #79cccd;
}

.t-veehouderij .c-icon--circle {
  color: #b1b498;
}

.t-teeltVanGewassen .c-icon--circle {
  color: #c6a893;
}

.t-import .c-icon--circle {
  color: #78b6e4;
}

.t-opslag .c-icon--circle {
  color: #b0b6c8;
}

.t-handel .c-icon--circle {
  color: #edc289;
}

.t-aquacultuur .c-icon--circle {
  color: #97d1a3;
}

/*------------------------------------*\
  #COMPAT -- Menu
\*------------------------------------*/
.c-menu,
.c-menu__mobile {
  background-color: #5229ba;
  color: #fff;
}
.c-menu a,
.c-menu__mobile a {
  color: #fff;
}
.c-menu a:hover, .c-menu a:focus,
.c-menu__mobile a:hover,
.c-menu__mobile a:focus {
  color: #f5f5f5;
}

@media (min-width: 64em) {
  .c-menu__item--toggl {
    color: #5229ba;
  }
  .c-menu__item--toggl a {
    color: #5229ba;
  }
  .c-menu__item--toggl a:hover, .c-menu__item--toggl a:focus {
    color: #4924a5;
  }
}

@media (min-width: 64em) {
  .c-submenu a {
    color: #000;
  }
  .c-submenu a:hover, .c-submenu a:focus {
    color: #5229ba;
  }
}

/*------------------------------------*\
  #COMPAT -- Page head
\*------------------------------------*/
.c-page-head__match-logo {
  align-self: flex-end;
}

/*------------------------------------*\
  #COMPAT -- Page foot
\*------------------------------------*/
.c-page-foot--secondary {
  background-color: #5229ba;
  color: #fff;
}
.c-page-foot--secondary a {
  color: #fff;
}
.c-page-foot--secondary a:hover, .c-page-foot--secondary a:focus {
  color: #dddddd;
}

/*------------------------------------*\
  #COMPAT -- Readmore
\*------------------------------------*/
.t-bereidingProductie .c-readmore__heading i {
  color: #79cccd;
}

.t-veehouderij .c-readmore__heading i {
  color: #b1b498;
}

.t-teeltVanGewassen .c-readmore__heading i {
  color: #c6a893;
}

.t-import .c-readmore__heading i {
  color: #78b6e4;
}

.t-opslag .c-readmore__heading i {
  color: #b0b6c8;
}

.t-handel .c-readmore__heading i {
  color: #edc289;
}

.t-aquacultuur .c-readmore__heading i {
  color: #97d1a3;
}

@media (min-width: 30em) {
  .c-readmore {
    flex-basis: 48%;
  }
}
@media (min-width: 64em) {
  .c-readmore {
    flex-basis: 31%;
  }
}
@media (min-width: 80em) {
  .c-readmore {
    flex-basis: 23%;
  }
}

/*------------------------------------*\
  #COMPAT -- Search
\*------------------------------------*/
.c-search__input:focus {
  border-color: #000;
}
.c-search__input:focus + .c-input__inline-label {
  border-color: #000;
  background-color: #f5f5f5;
}

.c-tile.c-search__result {
  width: 100%;
}

.c-search__results {
  flex-basis: 73%;
}

.c-search__help {
  flex-basis: 20%;
}

.c-search__resultDesc {
  color: #000;
}
a .c-search__resultDesc {
  color: #000;
}

/*------------------------------------*\
  #COMPAT -- Tiles
\*------------------------------------*/
.c-tile {
  box-shadow: -1px -1px 3px 0px rgba(0, 0, 0, 0.2), 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
  display: block;
}
@media (min-width: 45em) {
  .c-tile {
    flex-basis: auto;
    width: calc(50% - 1.4em);
  }
}
@media (min-width: 80em) {
  .c-tile {
    flex-basis: auto;
    width: calc(33.333% - 1.4em);
  }
}
@media (min-width: 100em) {
  .c-tile {
    flex-basis: auto;
    width: calc(25% - 1.4em);
  }
}
.c-tile:hover .c-btn, .c-tile:focus .c-btn {
  background-color: #4924a5;
  color: #f5f5f5;
}

@media (min-width: 80em) and (max-width: 99.99em) {
  .c-tile--total4 .c-tile--num4 {
    display: flex;
  }
}

.c-tile__shortdesc {
  color: #000;
}

.c-tile__head > h1,
.c-tile__head > h2 {
  color: #5229ba;
}

.c-tile__article .c-flag {
  height: 5em;
}
.c-tile__article .c-btn__readmore {
  width: calc(100% + 3em);
}
.c-tile__article:hover .c-btn__readmore, .c-tile__article:focus .c-btn__readmore {
  border-color: #4924a5;
}

.t-bereidingProductie .c-tile:hover .c-btn, .t-bereidingProductie .c-tile:focus .c-btn {
  background-color: #79cccd;
}
.t-bereidingProductie .c-tile__head > h1,
.t-bereidingProductie .c-tile__head > h2 {
  color: #79cccd;
}
.t-bereidingProductie .c-tile__article:hover .c-btn__readmore, .t-bereidingProductie .c-tile__article:focus .c-btn__readmore {
  border-color: #66c5c6;
}

.t-veehouderij .c-tile:hover .c-btn, .t-veehouderij .c-tile:focus .c-btn {
  background-color: #b1b498;
}
.t-veehouderij .c-tile__head > h1,
.t-veehouderij .c-tile__head > h2 {
  color: #b1b498;
}
.t-veehouderij .c-tile__article:hover .c-btn__readmore, .t-veehouderij .c-tile__article:focus .c-btn__readmore {
  border-color: #a6a989;
}

.t-teeltVanGewassen .c-tile:hover .c-btn, .t-teeltVanGewassen .c-tile:focus .c-btn {
  background-color: #c6a893;
}
.t-teeltVanGewassen .c-tile__head > h1,
.t-teeltVanGewassen .c-tile__head > h2 {
  color: #c6a893;
}
.t-teeltVanGewassen .c-tile__article:hover .c-btn__readmore, .t-teeltVanGewassen .c-tile__article:focus .c-btn__readmore {
  border-color: #bd9b82;
}

.t-import .c-tile:hover .c-btn, .t-import .c-tile:focus .c-btn {
  background-color: #78b6e4;
}
.t-import .c-tile__head > h1,
.t-import .c-tile__head > h2 {
  color: #78b6e4;
}
.t-import .c-tile__article:hover .c-btn__readmore, .t-import .c-tile__article:focus .c-btn__readmore {
  border-color: #63abe0;
}

.t-opslag .c-tile:hover .c-btn, .t-opslag .c-tile:focus .c-btn {
  background-color: #b0b6c8;
}
.t-opslag .c-tile__head > h1,
.t-opslag .c-tile__head > h2 {
  color: #b0b6c8;
}
.t-opslag .c-tile__article:hover .c-btn__readmore, .t-opslag .c-tile__article:focus .c-btn__readmore {
  border-color: #a1a8be;
}

.t-handel .c-tile:hover .c-btn, .t-handel .c-tile:focus .c-btn {
  background-color: #edc289;
}
.t-handel .c-tile__head > h1,
.t-handel .c-tile__head > h2 {
  color: #edc289;
}
.t-handel .c-tile__article:hover .c-btn__readmore, .t-handel .c-tile__article:focus .c-btn__readmore {
  border-color: #eab773;
}

.t-aquacultuur .c-tile:hover .c-btn, .t-aquacultuur .c-tile:focus .c-btn {
  background-color: #97d1a3;
}
.t-aquacultuur .c-tile__head > h1,
.t-aquacultuur .c-tile__head > h2 {
  color: #97d1a3;
}
.t-aquacultuur .c-tile__article:hover .c-btn__readmore, .t-aquacultuur .c-tile__article:focus .c-btn__readmore {
  border-color: #85c993;
}

/*------------------------------------*\
  #RESPONSIVE TABLES
\*------------------------------------*/
.c-table__container table {
  background-color: #fff;
}
.c-table__container tr {
  border-bottom-color: #5229ba;
}
.c-table__container td, .c-table__container th {
  border-left-color: #5229ba;
}
.c-table__container .c-table__firstcell {
  border-right-color: #5229ba;
}

.c-table__firstcell--spacer {
  color: #fff;
}

.c-tablestyle--bg table {
  background-color: #5229ba;
  border-color: #5229ba;
}
.c-tablestyle--bg tr {
  border-bottom-color: #fff;
}
.c-tablestyle--bg td, .c-tablestyle--bg th {
  border-left-color: #fff;
}
.c-tablestyle--bg .c-table__firstcell {
  border-right-color: #fff;
}

/*------------------------------------*\
  #Richtables
\*------------------------------------*/
.c-richtable {
  background-color: #f5f5f5;
}

.c-richtable__rowend {
  border-bottom: 1px solid #fff;
}

.c-richtable__cell {
  border-left: 1px solid #fff;
}